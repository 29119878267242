<template>
  <div class="contact" id="Contact"> 
    <Header/>
    <div class="other__narrow__banner">
      <img  :src="'/tw/upload/' + contact.image" alt="" />
      <div class="other__narrow__banner__title">
        <div class="title_container">
          <h4>{{contact.title}}</h4>
        </div>
      </div>
    </div>
    <div class="other__ck__block contact__section">
      <div class="container">
        <div class="other__ck__title title__bar">
          <h2>{{itemOne.title}}</h2>
        </div>
        <div class="other__ck__img contact__block">
          <div class="other__ck__img__box fit__img">
            <img src="../assets/pic/contact__img1.png" alt="" />
          </div>
          <div class="contact__info__list" v-html="itemOne.content">
          </div>
        </div>
      </div>
    </div>
    <div class="other__ck__block">
      <div class="container">
        <div class="other__ck__title title__bar">
          <h2>服務信箱</h2>
        </div>
        <div class="other__forms"> 
          <form class="needs-validation" novalidate>
            <div class="form-row">
              <div class="col-md-6 mb-3">
                <label for="validationCustom01">你的姓名</label>
                <input
                  v-model="name"
                  type="text"
                  class="form-control"
                  id="validationCustom01"
                  placeholder="請輸入姓名"
                  value="name"
                  required
                />
                <div class="invalid-feedback">
                  請輸入姓名
                </div>
              </div>
              <div class="col-md-6 mb-3 without_validate_wrapper">
                <label for="validationCustom02">服務公司</label>
                <input
                  v-model="company"
                  type="text"
                  class="without_validate_input"
                  id="validationCustom02"
                  placeholder="請輸入公司"
                  value="company"
                />
                <div class="invalid-feedback">
                  請輸入公司
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 mb-3 without_validate_wrapper">
                <label for="validationCustom01">聯絡電話</label>
                <input
                  v-model="phone"
                  type="text"
                  class="without_validate_input"
                  id="validationCustom01"
                  placeholder="請輸入電話"
                  value="phone"
                />
                <div class="invalid-feedback">
                  請輸入電話
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="validationCustom02">E-mail</label>
                <input
                  v-model="email"
                  type="email"
                  class="form-control"
                  id="validationCustom02"
                  placeholder="請輸入E-mail"
                  value="email"
                  required
                />
                <div class="invalid-feedback">
                  請輸入email
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 mb-3 without_validate_wrapper">
                <label for="validationCustom01">聯絡傳真</label>
                <input
                  v-model="fax"
                  type="text"
                  class="without_validate_input"
                  id="validationCustom01"
                  placeholder="請輸入傳真"
                  value="fax"
                />
                <div class="invalid-feedback">
                  請輸入傳真
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label for="validationCustom01">訊息內容</label>
              <textarea
                v-model="content"
                class="form-control"
                id="validationTextarea"
                placeholder="訊息內容"
                required
              ></textarea>
              <div class="invalid-feedback">
                請輸入訊息內容
              </div>
            </div>
            <div class="form-group">
              <div class="form-check">
                <input
                  v-model="checkbox"
                  class="form-check-input"
                  type="checkbox"
                  value="checkbox"
                  id="invalidCheck2"
                  required
                />
                <label class="form-check-label" for="invalidCheck2" style="color:black">
                  本人已閱讀並同意本公司
                </label>
                <span>
                  <a class="other__ck__text__green"
                  data-toggle="modal" 
                  data-target="#exampleModal"
                  >
                    個人資料蒐集政策
                  </a>
                </span>
                
                <div class="invalid-feedback">
                  尚未同意個人資料蒐集政策
                </div>
              </div>
            </div>
            
            <div class="modal fade" id="exampleModal"
                  tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">個人資料收集政策</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                <div class="modal-body" v-html="itemTwo.content">
                </div>
              </div>
              </div>
            </div>
            <button :disabled ="disable" class="btn btn_green btn_submit" @click.prevent.stop="SubmitData" type="submit" >
              確認送出
            </button>  
          </form>
        </div>
      </div>
    </div>
    <template>
      <vue-recaptcha ref="recaptcha" @verify="onVerify" @expired="onExpired" :loadRecaptchaScript="true" sitekey="6Ld_ohoeAAAAAHqE5M_OY3nX2uwAY6ki5LeYvSJv" @error="errorMethod"
      size="invisible"/>
    </template>
  </div>
  
</template>



<script>
import { apiContact } from "@/api/pages.js";
import { VueRecaptcha } from 'vue-recaptcha';
import { Toast } from "./../utils/helpers"
import formPostAPI from "./../api/formPost"
import Header from './../components/Header'
// console.log(formPostAPI.SubmitContactPost)
export default {
  name: "Contact",
  components: {
    Header,
    VueRecaptcha 
  },
  data(){
    return{
      item: {},
      contact: {},
      itemOne: {},
      itemTwo:{},
      name: '',
      company: '',
      phone: '',
      email: '',
      fax: '',
      content: '',
      checkbox: false,
      token: '',
      disable: false
    }
  },
  metaInfo: {
    meta:[
      {
        property: 'og:url',
        content: 'https://www.elevator.com.tw/tw#/'
      },
      {
        property: 'og:locale',
        content: 'zh_TW'
      },
      {
        property: 'og:type',
        content: 'website'
      },
      {
        property: 'og:description',
        content: 'Contact相對於小縮圖而言，大縮圖能顯示的文字量是比較少的，所以圖片上的資訊更加重要'
      },
      {
        property: 'og:title',
        content: '大縮圖分享連結Contact',
        vmid: 'og:title'
      },
      {
        property: 'og:image:type',
        content: 'image/png'
      },
      {
        property: 'og:image',
        content: 'https://github.com/dean9703111/open_graph_sample/blob/master/img/big_thumbnail.png?raw=true'
      },
      {
        property: 'og:image:width',
        content: '1400'
      },
      {
        property: 'og:image:height',
        content: '770'
      },
    ]
  },
  mounted() {
    this.getData()
    document.querySelector('body').scrollIntoView({behavior: "smooth"})
  },
  methods: {
    async postDataHandler () {
      const form = document.querySelector('.needs-validation')
      this.disable = true
      try {
        const data_JSON = {
          name: this.name,
          company: this.company,
          phone: this.phone,
          email: this.email,
          fax: this.fax,
          content: this.content,
          token: this.token
        }
        const response = await formPostAPI.SubmitContactPost({ data_JSON })
        console.log(response)
        if(response.data.result === 'OK'){
        // if(response.data.reCAPTCHA.success){
          Toast.fire({
            icon: 'success',
            title: '資料已送出！'
          })
          this.name = ''
          this.company = ''
          this.phone = ''
          this.email = ''
          this.fax = ''
          this.content = ''
          this.checkbox = false
          form.classList.remove('was-validated')
        }
      }
      catch {
        Toast.fire({
          icon: 'warning',
          title: '資料傳送失敗, 請稍候！'
        })
        console.error('error')
      }
      this.disable = false
    },
    onVerify: function (response) {
      this.token = response
      this.postDataHandler()
    },
    onExpired: function () {
      console.log('Expired')
    },
    errorMethod: function () {
      console.log('觸發 vue-recaptcha errorMethod')
    },
    resetRecaptcha() {
      console.log('進入resetRecaptcha')
      this.$refs.recaptcha.reset() // Direct call reset method
    },
    async SubmitData () {
      const form = document.querySelector('.needs-validation')
      form.classList.add('was-validated')
      if(!form.checkValidity()) return
      this.$refs.recaptcha.execute()
    },
    async getData(){
      const res = await apiContact();
      this.item = res.data;
      const {contact,item,item1} = res.data
      this.contact = {
        ...contact[0]
      }
      this.itemOne = {
        ...item
      }
      this.itemTwo = {
        ...item1
      }
    },
  },
};
</script>

<style lang="css">
    .modal-dialog{
      max-height: 80%;
      max-width: 90%;
      margin: auto;
      margin-top: 45px;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    input[type="number"]{
        -moz-appearance: textfield;
    }
    @media screen and (min-width: 768px){
      .modal-dialog {
        max-width: 70%;
        max-height: 500px;
        
      }  
    }
    @media screen and (min-width: 1200px){
      .modal-dialog {
        margin-top: 20vh; 
      }  
    }
</style>